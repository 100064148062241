import React, { useState, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { head, get, find } from '../../lib/nodash';
import { Box, Button, Stack, Heading, ThemeContext } from 'grommet';
import { FormDown } from 'grommet-icons';
import IconAdd from '../Icons/IconAdd';

import InputNativeSelect from '../Inputs/InputNativeSelect';
import TextTitle from '../TextTitle';
import { setActiveModal } from '../../state/ui/uiSlice';
import useBuybarUi from './useBuybarUi';

const ProductGiftCardBuyOptionsGiftCard = ({ variants, productTitle }) => {
  const [variant, setVariant] = useState(head(variants));
  const dispatch = useDispatch();
  const { buybarRef, isAtTop } = useBuybarUi();
  const theme = useContext(ThemeContext);

  const handleChoose = () => {
    dispatch(
      setActiveModal({
        type: 'component',
        value: 'gift-card-form',
        meta: {
          variantId: get('shopifyId', variant),
          onComplete: () => dispatch(setActiveModal(null)),
        },
      })
    );
  };

  return (
    <Box
      direction="row"
      border={{ side: 'all', size: 'small' }}
      align="center"
      background={isAtTop ? 'transparent' : 'white'}
      ref={buybarRef}
    >
      {productTitle && !isAtTop && (
        <Box
          pad="medium"
          flex={{ grow: 1 }}
          border={{ side: 'right', size: 'small' }}
          fill="vertical"
        >
          <Heading level={5}>{productTitle}</Heading>
        </Box>
      )}
      <Stack fill="vertical" guidingChild="last">
        <Box
          fill
          align="center"
          flex={true}
          justify="between"
          direction="row"
          pad={{ horizontal: 'medium', vertical: 'small' }}
        >
          <TextTitle size="small" as="label" htmlFor="quantity">
            VALUE
          </TextTitle>
          <Box>
            <FormDown color="black" />
          </Box>
        </Box>
        <InputNativeSelect
          value={variant.shopifyId}
          name="quantity"
          onChange={(e) => {
            setVariant(find({ shopifyId: e.target.value }, variants));
          }}
          style={{
            padding: `10px 3em 10px 6em`,
            background: 'transparent',
            fontSize: theme.text.small.size,
          }}
        >
          {variants.map((x) => (
            <option key={x.id} value={x.shopifyId}>
              {x.title}
            </option>
          ))}
        </InputNativeSelect>
      </Stack>
      <Button
        icon={<IconAdd />}
        reverse
        primary
        label="Add to Cart"
        size="small"
        full
        onClick={handleChoose}
      />
    </Box>
  );
};

ProductGiftCardBuyOptionsGiftCard.propTypes = {
  variants: PropTypes.array.isRequired,
  productTitle: PropTypes.string,
};

export default memo(ProductGiftCardBuyOptionsGiftCard);
